import {
  GetStaticPaths,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next'
import {PHASE_PRODUCTION_BUILD} from 'next/constants'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import cache from '../lib/algolia-cache'
import {
  getPage,
  getExtraBlogArticles,
  getExtraWhitepapers,
  getExtraGuides,
  getExtraStudiens,
  getExtraEbooks,
  getCasesAndFilters,
  getResources,
  getResourcesLinkedToPillar,
  syncTranslations,
  getBestSellers,
  getLinkedSubscriptionTryoutPage,
  getMapping,
  getTrainingRankingTaxonomies,
} from '../lib/api'
import Layout from '../components/layout'
import Breadcrumb from '../components/shared/Breadcrumb'
import ContentTypeMapper from '../components/content-types/ContentTypeMapper'
import ParagraphMapper from '../components/paragraphs/ParagraphMapper'
import type {
  BlogArticleNode,
  WhitepaperNode,
  EbookNode,
  StudienNode,
  GuideNode,
  TrainingPageNode,
  Node,
  MenuLink,
  SubscriptionPageNode,
  ContentPageNode,
} from '../types/content-types'
import {getSubscriptionPage, getTrainingPage} from '../lib/drupal'
import type {TrainingSubscriptionAlgolia} from '../types/algolia'
import type {Response} from '../types/response'
import {
  MENUS_CACHEFILENAME,
  localizedCacheFileExists,
  saveToLocalizedCacheFile,
} from '../lib/api/cacheFileHelper'
import {SharedDataStrategy} from '../lib/urlMapStrategies/sharedDataStrategy'
import {SharedDataFileSystem} from '../lib/urlMapStrategies/sharedDataStrategy.filesystem'
import {SharedDataRealtime} from '../lib/urlMapStrategies/sharedDataStrategy.realtime'
import logger from '../utils/logger'
import {generateSiteMap} from '../lib/api/generateSiteMapForLanguage'
import {generateMenus} from '../lib/api/generateMenuForLanguage'
import {Menu} from '../lib/urlMapStrategies/menu'
import {generateRedirectList} from '../lib/redirects'
import {SharedDataRemoteCache} from '../lib/urlMapStrategies/sharedDataStrategy.remoteCache'
import {purgeNextjsStaticFiles} from '../lib/api/purgeNextjsStaticFiles'
import {BreadCrumbType} from '../types/breadcrumb'

export type SlugProps = InferGetStaticPropsType<typeof getStaticProps>

function Page({
  page,
  breadCrumb,
  mainMenuItems,
  functionalNavItems,
  socialMenuItems,
  footerMenuItems,
  doormatMenuItems,
  relatedBlogArticles,
  relatedWhitepapers,
  relatedEbooks,
  relatedStudiens,
  relatedGuides,
  resources,
  casesAndFilters,
  siteSwitchMenuItems,
  doormatButtonMenuItems,
  languageSwitchMenuItems,
  response,
  trainingRankingTaxonomies,
  bestsellers,
  linkedSubscriptionTryoutPageId,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const showBreadCrumb =
    page?.type !== 'node--search_page' && page?.type !== 'node--homepage'

  return (
    <Layout
      mainMenuItems={mainMenuItems}
      functionalNavItems={functionalNavItems}
      doormatMenuItems={doormatMenuItems}
      socialMenuItems={socialMenuItems}
      footerMenuItems={footerMenuItems}
      siteSwitchMenuItems={siteSwitchMenuItems}
      doormatButtonMenuItems={doormatButtonMenuItems}
      languageSwitchMenuItems={languageSwitchMenuItems}
      page={page}
    >
      {showBreadCrumb && <Breadcrumb breadCrumb={breadCrumb} />}
      <ContentTypeMapper
        page={page}
        resources={resources}
        relatedBlogArticles={relatedBlogArticles}
        relatedWhitepapers={relatedWhitepapers}
        relatedEbooks={relatedEbooks}
        relatedGuides={relatedGuides}
        relatedStudiens={relatedStudiens}
        linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
        response={response}
        trainingRankingTaxonomies={trainingRankingTaxonomies}
      >
        {page?.relationships.field_paragraphs?.data.map(paragraph => (
          <ParagraphMapper
            paragraph={paragraph}
            casesAndFilters={casesAndFilters}
            key={paragraph.id}
            response={response}
            bestsellers={bestsellers}
          />
        ))}
        {page?.relationships.field_paragraph?.data ? (
          <ParagraphMapper
            paragraph={page?.relationships.field_paragraph?.data}
            response={response}
          />
        ) : null}
      </ContentTypeMapper>
    </Layout>
  )
}

export const getStaticPaths: GetStaticPaths = async context => {
  await syncTranslations()
  const menusAllLanguages = await generateMenus(context.locales!)
  const saveAllMenusToCacheFilePromises = menusAllLanguages.map((x, i) => {
    Object.keys(x).forEach(key => {
      saveToLocalizedCacheFile(
        `${MENUS_CACHEFILENAME}_${key}`,
        x[key as keyof Menu],
        context.locales![i],
      )
    })
  })
  await Promise.all(saveAllMenusToCacheFilePromises)
  const homePages: {
    id: string | undefined
    type: string
    path: string
    locale: string
  }[] = []
  for (let i = 0; i < (context.locales ?? []).length; i += 1) {
    const locale = context.locales![i]
    const homePage = {
      id: undefined,
      type: 'node/homepage',
      locale,
      path: '/',
    }
    homePages.push(homePage)
  }
  if (
    process.env.LIGHTHOUSE_ACTIVE ||
    (process.env.SKIP_STATIC_PATHS &&
      parseInt(process.env.SKIP_STATIC_PATHS) === 1)
  ) {
    return {
      paths: homePages.map(hp => ({
        params: {slug: [''], locale: hp.locale},
      })),
      fallback: 'blocking',
    }
  }

  const sitemap = await generateSiteMap('bookingexcluded')
  const paths = [...sitemap, ...homePages]
  return {
    paths: paths.map(path => ({
      params: {
        slug: path.path.replace('/', '').split('/'), // replace leading slash
        locale: path.locale,
      },
    })),
    fallback: 'blocking',
  }
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (process.env.NODE_ENV === 'production') {
    console.log(
      `revalidating /${context.locale}${
        context.params?.slug
          ? `/${(context.params?.slug as string[] | undefined)?.join('/')}`
          : ''
      }`,
    )
  }

  await syncTranslations()
  let sharedDataStrategy: SharedDataStrategy
  if (process.env.NODE_ENV !== 'production') {
    // npm run dev
    sharedDataStrategy = new SharedDataRealtime()
  } else if (
    await localizedCacheFileExists(
      `${MENUS_CACHEFILENAME}_main`,
      context.locale!,
    )
  ) {
    // buildtime OR localhost
    logger.info('filesystem strategy')
    sharedDataStrategy = new SharedDataFileSystem()
  } else if (context.preview!) {
    // preview OR runtime
    logger.info('realtime strategy')
    sharedDataStrategy = new SharedDataRealtime()
  } else {
    logger.info('remotecache strategy') // runtime
    sharedDataStrategy = new SharedDataRemoteCache()
  }

  if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
    const redirectList = await generateRedirectList()
    if (context.params && redirectList?.length > 0) {
      const renderedPagePath = Array.isArray(context.params?.slug)
        ? `/${context.params?.slug?.join('/')}`
        : null
      if (renderedPagePath) {
        const redirectFound = redirectList.find(redirect => {
          const basePath =
            process.env?.NEXT_PUBLIC_BASEPATH?.toLowerCase() || ''
          const redirectUrlWithoutBasePath = redirect.from.split(basePath)[1]
          if (redirectUrlWithoutBasePath === renderedPagePath) {
            return redirect
          }
        })
        if (redirectFound) {
          return {
            redirect: {
              destination: redirectFound.to,
              statusCode: +redirectFound.redirect_status,
            },
            revalidate: process.env.APP_ENV === 'prd' ? 1800 : 60,
          }
        }
      }
    }
  }

  const menuItems = await sharedDataStrategy.getMenuItems(context.locale!)

  let page: Node | null = null
  let response: Response | null = null
  const langCode = context.locale!.toLowerCase()
  let slugParams = !context.params?.slug
    ? []
    : Array.isArray(context.params?.slug)
    ? context.params?.slug
    : [context.params?.slug]

  if (!context.params?.slug) {
    // console.log('getting homepage')
    response = await getPage(
      undefined,
      'node/homepage',
      context.locale!,
      context.preview,
    )
    page = response?.included?.find(
      el => el.type === 'node--homepage',
    ) as ContentPageNode
  } else {
    const mapping = await getMapping(
      context.locale,
      context.params?.slug as string[] | undefined,
    )
    if (!mapping) {
      await purgeNextjsStaticFiles(langCode, slugParams)
      return {
        notFound: true,
        revalidate: 60,
      }
    }

    await purgeNextjsStaticFiles(langCode, slugParams ?? [])
    if (mapping.type === 'node--training') {
      response = await getTrainingPage(mapping.id, context.locale!)
      page = response?.included?.find(
        el => el.type === 'node--training',
      ) as TrainingPageNode
    } else if (mapping.type === 'node--subscription') {
      response = await getSubscriptionPage(mapping.id, context.locale!)
      page = response?.included?.find(
        el => el.type === 'node--subscription',
      ) as SubscriptionPageNode
    } else {
      response = await getPage(
        mapping.id,
        mapping.type.replace('--', '/'),
        context.locale!,
      )
      page = response?.included?.find(
        el => el.type === mapping?.type,
      ) as SubscriptionPageNode
    }
  }

  // page does not exist OR unpublished
  if (!page || !page?.attributes?.status) {
    // remove page from server
    await purgeNextjsStaticFiles(langCode, slugParams ?? [])
    return {
      notFound: true,
      revalidate: 60,
    }
  }

  const breadCrumb: BreadCrumbType[] = page.attributes.breadcrumbs ?? []
  let linkedSubscriptionTryoutPageId: string | undefined | null = null
  let relatedBlogArticles: Response | null = null
  let relatedWhitepapers: Response | null = null
  let relatedEbooks: Response | null = null
  let relatedStudiens: Response | null = null
  let relatedGuides: Response | null = null
  let casesAndFilters: Response[] | null = null
  let resources: Response[] | null = null
  let trainingRankingTaxonomies: Response | null = null
  const caseOverviewParagraph = page?.relationships.field_paragraphs?.data.find(
    paragraph => paragraph.type === 'paragraph--case_overview',
  )
  if (caseOverviewParagraph) {
    casesAndFilters = await getCasesAndFilters(context.locale!)
  }
  let bestsellers: TrainingSubscriptionAlgolia[] | null = null
  const bestSellersParagraph = page?.relationships.field_paragraphs?.data.find(
    paragraph => paragraph.type === 'paragraph--bestsellers',
  )
  if (bestSellersParagraph) {
    bestsellers = await getBestSellers(cache, context.locale!)
  }
  if (page?.type === 'node--resource_overview') {
    resources = await getResources(context.locale!)
  }

  if (page?.type === 'node--blog_article') {
    relatedBlogArticles = await getExtraBlogArticles(
      (page as BlogArticleNode).id,
      (page as BlogArticleNode).relationships.field_main_topic?.data?.id!,
      context.locale!,
    )
  }

  if (page?.type === 'node--whitepaper') {
    relatedWhitepapers = await getExtraWhitepapers(
      (page as WhitepaperNode).id,
      (page as WhitepaperNode).relationships.field_main_topic?.data?.id!,
      context.locale!,
    )
  }

  if (page?.type === 'node--e_book') {
    relatedEbooks = await getExtraEbooks(
      (page as EbookNode).id,
      (page as EbookNode).relationships.field_main_topic?.data?.id!,
      context.locale!,
    )
  }

  if (page?.type === 'node--studien') {
    relatedStudiens = await getExtraStudiens(
      (page as StudienNode).id,
      (page as StudienNode).relationships.field_main_topic?.data?.id!,
      context.locale!,
    )
  }

  if (page?.type === 'node--guide') {
    relatedGuides = await getExtraGuides(
      (page as GuideNode).id,
      (page as GuideNode).relationships.field_main_topic?.data?.id!,
      context.locale!,
    )
  }
  if (page?.type === 'node--pillar_page') {
    resources = await getResourcesLinkedToPillar(page?.id, context.locale!)
  }
  if (page?.type === 'node--subscription') {
    linkedSubscriptionTryoutPageId = await getLinkedSubscriptionTryoutPage(
      (page as SubscriptionPageNode).attributes.field_subscription_id,
      context.locale!,
    )
  }
  if (
    page?.type === 'node--category_page' ||
    page?.type === 'node--search_page'
  ) {
    trainingRankingTaxonomies = await getTrainingRankingTaxonomies(
      context.locale!,
    )
  }
  const environment = process.env.APP_ENV!
  const mainMenuItemsFiltered: MenuLink[] | undefined = menuItems.main.filter(
    el =>
      el.attributes.enabled || el.type === 'paragraph--navigation_highlight',
  )
  const functionalMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.functional.filter(el => el.attributes.enabled)
  const socialMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.social.filter(el => el.attributes.enabled)
  const doormatMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.doormat.filter(el => el.attributes.enabled)
  const footerMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.footer.filter(el => el.attributes.enabled)
  const siteSwitchMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.siteSwitch.filter(el => el.attributes.enabled)
  const doormatButtonMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.doormatButton.filter(el => el.attributes.enabled)
  const languageSwitchMenuItemsFiltered: MenuLink[] | undefined =
    menuItems.languageSwitch
  return {
    props: {
      ...(await serverSideTranslations(context.locale!, ['common'])),
      page: page ?? null,
      response: response ?? null,
      breadCrumb,
      mainMenuItems: {
        included: mainMenuItemsFiltered ?? [],
      },
      functionalNavItems: {
        included: functionalMenuItemsFiltered ?? [],
      },
      socialMenuItems: {
        included: socialMenuItemsFiltered ?? [],
      },
      doormatMenuItems: {
        included: doormatMenuItemsFiltered ?? [],
      },
      footerMenuItems: {
        included: footerMenuItemsFiltered ?? [],
      },
      siteSwitchMenuItems: {
        included: siteSwitchMenuItemsFiltered ?? [],
      },
      doormatButtonMenuItems: {
        included: doormatButtonMenuItemsFiltered ?? [],
      },
      languageSwitchMenuItems: {
        included: languageSwitchMenuItemsFiltered ?? [],
      },
      relatedBlogArticles,
      relatedWhitepapers,
      relatedEbooks,
      relatedGuides,
      relatedStudiens,
      resources,
      trainingRankingTaxonomies,
      casesAndFilters,
      environment,
      bestsellers,
      linkedSubscriptionTryoutPageId: linkedSubscriptionTryoutPageId ?? null,
    },
    revalidate: process.env.APP_ENV === 'prd' ? 1800 : 60,
  }
}

export default Page
