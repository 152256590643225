import {useTranslation} from 'next-i18next'
import Link from 'next/link'
import striptags from 'striptags'
import {
  BookingPagesSubscription,
  BookingPagesSubscriptionTryout,
} from '../../../consts/booking-pages'
import type {SubscriptionPageNode} from '../../../types/content-types'
import {difficultyLevelToLabelDataLayer} from '../../../utils/datalayer'
import {formattedCourseType} from '../../../utils/course-type-code'

declare const window: any

type SubscribeLinkSubscriptionProps = {
  subscriptionPage: SubscriptionPageNode
  courseTypeCode: string | undefined
  linkedSubscriptionTryoutPageId?: string | null
}

const SubscribeLinkSubscription = ({
  subscriptionPage,
  courseTypeCode,
  linkedSubscriptionTryoutPageId,
}: SubscribeLinkSubscriptionProps) => {
  const {t} = useTranslation('common')
  const onSubscribeClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ecommerce: null})
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: striptags(t('currency_code')),
        value: subscriptionPage.attributes.field_price_non_member,
        items: [
          {
            item_id: subscriptionPage.attributes.field_subscription_id,
            item_name: subscriptionPage.attributes.title,
            item_category: formattedCourseType(courseTypeCode),
            item_category2: difficultyLevelToLabelDataLayer(
              subscriptionPage.attributes.field_difficulty_level,
            ),
            price: subscriptionPage.attributes.field_price_non_member,
            quantity: 1,
          },
        ],
      },
    })
  }
  return (
    <div className="d-flex flex-column">
      <Link
        className="btn btn--red mt-4"
        onClick={() => onSubscribeClick()}
        href={`${striptags(t('booking_base_url'))}/${
          subscriptionPage.id
        }-abo/${t(striptags(BookingPagesSubscription.CHOOSESESSION))}`}
      >
        {striptags(t('subscriptionpage_subscribe'))}
      </Link>
      {!!linkedSubscriptionTryoutPageId ? (
        <Link
          href={`${striptags(
            t('booking_base_url'),
          )}/${linkedSubscriptionTryoutPageId}-abo/${t(
            striptags(BookingPagesSubscriptionTryout.CHOOSESESSION),
          )}`}
          className="btn btn--white mt-4"
          onClick={() => onSubscribeClick()}
        >
          {striptags(t('subscriptionpage_subscribe_tryout'))}
        </Link>
      ) : null}
    </div>
  )
}

export default SubscribeLinkSubscription
