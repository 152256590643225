import striptags from 'striptags'
import {TrainingPageNode} from '../../../types/content-types'

type LanguageProps = {
  // eslint-disable-next-line no-unused-vars
  t: (label: string) => string
  locale?: string
  linkedTrainings?: TrainingPageNode[]
  isOnSubscriptionPage?: boolean
}

const Language = ({
  t,
  locale,
  linkedTrainings,
  isOnSubscriptionPage = false,
}: LanguageProps) => (
  <div
    className={`d-flex align-items-center font-weight-bold ${
      isOnSubscriptionPage ? '' : 'pb-2'
    }`}
  >
    <i className="icon languageIcon" />
    <div>
      <span className="font-bolder">
        {t(`trainingsearch_${locale!.split('-')[0]}`)}
      </span>
      {linkedTrainings?.[0] && (
        <>
          {' '}
          /{' '}
          <a
            className="text-underline"
            href={`${process.env.NEXT_PUBLIC_BASEPATH_ALTERNATE}${linkedTrainings[0].attributes.path.alias}`}
          >
            {striptags(t('trainingpage_switchlanguage'))}
          </a>
        </>
      )}
    </div>
  </div>
)

export default Language
