export function getUrlFromRichtext(richtext: string = '') {
  const hrefStringIndex = richtext.indexOf('href="')
  const hrefSubString = richtext.substr(hrefStringIndex + 6)
  const hrefSubStringEndIndex = hrefSubString.indexOf('"')
  const url = hrefSubString.substr(6, hrefSubStringEndIndex - 6)
  return url ?? '/'
}

export function getTargetFromRichtext(richtext: string = '') {
  const targetStringIndex = richtext.indexOf('target="')
  if (targetStringIndex === -1) {
    return undefined
  }
  const targetSubString = richtext.substr(targetStringIndex + 8)
  const targetSubStringEndIndex = targetSubString.indexOf('"')
  const target = targetSubString.substr(0, targetSubStringEndIndex)
  return target ?? undefined
}
