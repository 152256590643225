import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import RightIcon from '../../../public/arrow-right-orange.svg'
import RichText from '../../shared/RichText'

type Link247Props = {
  courseTypeCode: string | undefined | null
}

const Link247 = ({courseTypeCode}: Link247Props) => {
  const {t} = useTranslation()
  const is247Enabled = process.env.NEXT_PUBLIC_LINK_247_ENABLED === '1'

  const isCourseTypeCodeValid =
    courseTypeCode === 'livewebinar' ||
    courseTypeCode === 'elearning' ||
    courseTypeCode === 'resourcelibrary'

  return (
    <>
      {isCourseTypeCodeValid && is247Enabled ? (
        <div className="c-training__hero_247">
          <div className="c-training__hero_247-button d-flex flex-row">
            <RichText html={t('trainingpage_247_button')} />
            <Image src={RightIcon} alt="arrow-right"></Image>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Link247
