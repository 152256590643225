import React from 'react'
import dynamic from 'next/dynamic'
import {Response} from '../../types/response'
import type {Node} from '../../types/content-types'
import Subscription from './Subscription'

const Training = dynamic(() => import('./TrainingPage'))
const ContentPage = dynamic(() => import('./ContentPage'))
const BlogArticle = dynamic(() => import('./BlogArticle'))
const Case = dynamic(() => import('./Case'))
const ResourceOverview = dynamic(() => import('./ResourceOverview'))
const PillarPage = dynamic(() => import('./PillarPage'))
const IframePage = dynamic(() => import('./IframePage'))
const TrainingSearchPage = dynamic(() => import('./TrainingSearchPage'))
const CategoryPage = dynamic(() => import('./CategoryPage'))
const Whitepaper = dynamic(() => import('./Whitepaper'))
const Ebook = dynamic(() => import('./Ebook'))
const Guide = dynamic(() => import('./Guide'))
const Studien = dynamic(() => import('./Studien'))

type Props = {
  page?: Node | null
  relatedBlogArticles?: Response | null
  relatedWhitepapers?: Response | null
  relatedEbooks?: Response | null
  relatedGuides?: Response | null
  relatedStudiens?: Response | null
  resources?: Response[] | null
  children?: React.ReactNode
  response?: Response | null
  linkedSubscriptionTryoutPageId?: string | null
  trainingRankingTaxonomies?: Response | null
}

const contentTypes: {
  [key: string]: () => React.ComponentType<Props>
} = {
  'node--content_page': () => ContentPage,
  'node--homepage': () => ContentPage,
  'node--blog_article': () => BlogArticle,
  'node--case': () => Case,
  'node--resource_overview': () => ResourceOverview,
  'node--pillar_page': () => PillarPage,
  'node--search_page': () => TrainingSearchPage,
  'node--iframe_page': () => IframePage,
  'node--whitepaper': () => Whitepaper,
  'node--e_book': () => Ebook,
  'node--guide': () => Guide,
  'node--studien': () => Studien,
  'node--category_page': () => CategoryPage,
  'node--training': () => Training,
  'node--subscription': () => Subscription,
}

const ContentTypeMapper = ({
  page,
  relatedBlogArticles,
  relatedWhitepapers,
  relatedEbooks,
  relatedGuides,
  relatedStudiens,
  resources,
  children,
  response,
  linkedSubscriptionTryoutPageId,
  trainingRankingTaxonomies,
}: Props) => {
  if (
    page?.type &&
    Object.prototype.hasOwnProperty.call(contentTypes, page.type)
  ) {
    const ContentTypeComponent = contentTypes[page!.type]()
    return (
      <ContentTypeComponent
        page={page}
        relatedBlogArticles={relatedBlogArticles}
        relatedWhitepapers={relatedWhitepapers}
        relatedEbooks={relatedEbooks}
        relatedGuides={relatedGuides}
        relatedStudiens={relatedStudiens}
        resources={resources}
        response={response}
        linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
        trainingRankingTaxonomies={trainingRankingTaxonomies}
      >
        {children}
      </ContentTypeComponent>
    )
  }
  return null
}

export default ContentTypeMapper
