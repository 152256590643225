import type {Response} from '../../../types/response'
import type {SubscriptionPageNode, Node} from '../../../types/content-types'
import Hero from '../../shared/TrainingSubscriptionHero'
import {ReactNode} from 'react'
import Sections from '../TrainingPage/Sections'

type Props = {
  page?: Node | null
  response?: Response | null
  children?: React.ReactNode
  linkedSubscriptionTryoutPageId?: string | null
}

type Section = {
  [key: string]: {
    content: string | ReactNode | undefined
  }
}

const Subscription = ({
  page: incomingPage,
  response,
  children,
  linkedSubscriptionTryoutPageId,
}: Props) => {
  const page = incomingPage as SubscriptionPageNode | null | undefined
  if (!page) {
    return null
  }
  const linkedTrainings = response?.included?.filter(el =>
    page?.relationships.field_language_references?.data.find(
      linkedTraining => linkedTraining.id === el.id,
    ),
  )

  const subscriptionPage = response?.included?.find(
    el => el.type === 'node--subscription',
  ) as SubscriptionPageNode

  const mainSections: Section = {
    goal: {
      content:
        subscriptionPage.attributes.field_sub_goal_description?.processed,
    },
    targetgroup: {
      content:
        subscriptionPage.attributes.field_sub_audience_description?.processed,
    },
    approach: {
      content:
        subscriptionPage.attributes.field_sub_approach_description?.processed,
    },
  }
  const otherSections: Section = {
    program: {
      content:
        subscriptionPage.attributes.field_sub_program_description?.processed,
    },
  }

  const mainSectionsArray = Object.keys(mainSections).filter(
    section => !!mainSections[section].content,
  )

  const sectionOddOrEven =
    mainSectionsArray.length === 0 ? 1 : mainSectionsArray.length % 2

  return (
    <div className="c-subscription">
      <Hero
        isTraining={false}
        response={response}
        trainingPage={page}
        linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
        linkedTrainings={linkedTrainings}
      />
      <Sections sections={mainSections} mainSection />
      <Sections sections={otherSections} sectionOddOrEven={sectionOddOrEven} />
      {children}
    </div>
  )
}

export default Subscription
