import Image from 'next/image'
import Link from 'next/link'
import striptags from 'striptags'
import networkIcon from '../../../public/NetwerkEvent2.svg'
import {
  getTargetFromRichtext,
  getUrlFromRichtext,
} from '../../../utils/url-from-richtext'
import {useTranslation} from 'next-i18next'
import {formattedCourseType} from '../../../utils/course-type-code'

type IconWithLabelProps = {
  icon?: string
  label?: string | undefined
  show?: boolean
  isCourseType?: boolean
  withoutPadding?: boolean
}

const IconWithLabel = ({
  icon,
  label,
  show = true,
  isCourseType = false,
  withoutPadding = false,
}: IconWithLabelProps) => {
  const {t} = useTranslation()
  if (!label || !show) {
    return null
  }
  const courseType = formattedCourseType(label)

  return (
    <div
      className={`d-flex align-items-center ${withoutPadding ? '' : 'pb-2'}`}
    >
      {isCourseType ? (
        <>
          {icon === '5' ? (
            <Image
              className={`${label}Icon icon`}
              alt="network icon"
              width={32}
              src={networkIcon}
            />
          ) : (
            <i className={`icon ${courseType}Icon icon`} />
          )}
          <div>{striptags(t(`course_type_${courseType}`))}</div>
        </>
      ) : (
        <i className={`icon ${icon}Icon`} />
      )}
      {icon === 'kmo' ? (
        <Link
          target={getTargetFromRichtext(label)}
          className="text-underline"
          href={getUrlFromRichtext(label)}
        >
          {striptags(label)}
        </Link>
      ) : null}
      {icon !== 'kmo' && !isCourseType ? <div>{label}</div> : null}
    </div>
  )
}

export default IconWithLabel
