import Link from 'next/link'
import {useCallback} from 'react'
import {useRouter} from 'next/router'
import {BreadCrumbType} from '../../types/breadcrumb'
import {v4 as uuidv4} from 'uuid'

type Props = {
  breadCrumb: BreadCrumbType[]
}

const Breadcrumb = ({breadCrumb}: Props) => {
  const router = useRouter()
  const locale = router?.locale ?? ''

  const urlWithoutLocale = useCallback(
    (url: string) =>
      url?.includes(locale) ? url.replace(`/${locale}`, '') : '',

    [locale],
  )

  if (!breadCrumb || breadCrumb.length === 0) {
    return null
  }
  return (
    <section className="c-breadcrumb">
      <ol itemScope itemType="http://schema.org/BreadcrumbList">
        <li
          className="d-flex align-items-center"
          itemScope
          itemType="http://schema.org/ListItem"
          itemProp="itemListElement"
        >
          <Link className="nav-link" itemProp="item" href="/">
            <meta itemProp="position" content="1" />
            <span itemProp="name">Home</span>
          </Link>
          <span className="c-breadcrumb__delimiter">&gt;</span>
        </li>
        {[...breadCrumb].reverse().map((breadCrumbItem, i) => {
          const id = uuidv4()
          return (
            <li
              className="d-flex align-items-center"
              key={id}
              itemScope
              itemType="http://schema.org/ListItem"
              itemProp="itemListElement"
            >
              {i === breadCrumb.length - 1 ? (
                <div className="nav-link">
                  <meta
                    itemProp="item"
                    content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${
                      process.env.NEXT_PUBLIC_BASEPATH
                    }${urlWithoutLocale(breadCrumbItem.path)}`}
                  />
                  <meta itemProp="position" content={String(i + 2)} />
                  <span itemProp="name">{breadCrumbItem.label}</span>
                </div>
              ) : (
                <Link
                  className="nav-link"
                  href={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${
                    process.env.NEXT_PUBLIC_BASEPATH
                  }${urlWithoutLocale(breadCrumbItem.path)}`}
                >
                  <meta
                    itemProp="item"
                    content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${
                      process.env.NEXT_PUBLIC_BASEPATH
                    }${urlWithoutLocale(breadCrumbItem.path)}`}
                  />
                  <meta itemProp="position" content={String(i + 2)} />
                  <span itemProp="name">{breadCrumbItem.label}</span>
                </Link>
              )}
              {i < breadCrumb.length - 1 && (
                <span className="c-breadcrumb__delimiter">&gt;</span>
              )}
            </li>
          )
        })}
      </ol>
    </section>
  )
}

export default Breadcrumb
