import { LevelLabel, LevelPercentage } from "."

type VacancyLevelProps = {
  level: string
  vacancyLevelClass: LevelLabel
  vacancyLevelPercentage: LevelPercentage
  vacancyLevelLabel: LevelLabel
}

const VacancyLevel = ({
  level,
  vacancyLevelClass,
  vacancyLevelPercentage,
  vacancyLevelLabel,
}: VacancyLevelProps) => {
  if (!level) {
    return null
  }
  return (
    <>
      <progress
        className={`c-training__hero-info-bookedPercentage ${vacancyLevelClass[level]}`}
        value={vacancyLevelPercentage[level]}
        max={100}
      />
      <span className="c-training__hero-info-bookedPercentageLabel font-bolder">
        {vacancyLevelLabel[level]}
        {level === '1' && `(${vacancyLevelPercentage[level]}%)`}
      </span>
    </>
  )
}

export default VacancyLevel
