import {useTranslation} from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import striptags from 'striptags'
import type {TrainingPageNode} from '../../../types/content-types'
import {DataContext} from '../../../utils/data-context'
import {difficultyLevelToLabelDataLayer} from '../../../utils/datalayer'
import GenericLink from '../../shared/Link'
import {formattedCourseType} from '../../../utils/course-type-code'

declare const window: any

type SubscribeInterestedLinkProps = {
  trainingPage: TrainingPageNode
  showAsInterested?: boolean
  courseTypeCode: string | undefined
  showAsPrimaryBtn?: boolean
}

const SubscribeInterestedLink = ({
  trainingPage,
  showAsInterested = false,
  courseTypeCode,
  showAsPrimaryBtn = false,
}: SubscribeInterestedLinkProps) => {
  const {setIsInterestedDialogOpen} = React.useContext(DataContext)
  const {t} = useTranslation('common')
  const onSubscribeClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ecommerce: null})
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: striptags(t('currency_code')),
        value: trainingPage.attributes.field_price_non_member,
        items: [
          {
            item_id: trainingPage.attributes.field_training_id,
            item_name: trainingPage.attributes.title,
            item_category: formattedCourseType(courseTypeCode),
            item_category2: difficultyLevelToLabelDataLayer(
              trainingPage.attributes.field_difficulty_level,
            ),
            price: trainingPage.attributes.field_price_non_member,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (trainingPage.attributes.field_registration_url && !showAsInterested) {
    return (
      <GenericLink
        extraClass="btn btn--red mt-4"
        link={trainingPage.attributes.field_registration_url}
        onClick={() => onSubscribeClick()}
      >
        {striptags(t('trainingpage_subscribe'))}
      </GenericLink>
    )
  }
  if (
    trainingPage.attributes.field_open_for_subscription &&
    !showAsInterested
  ) {
    return (
      <Link
        className="btn btn--red mt-4"
        onClick={() => onSubscribeClick()}
        href={`${striptags(t('booking_base_url'))}/${
          trainingPage.id
        }/${striptags(t('booking_url_choosesession'))}`}
      >
        {striptags(t('trainingpage_subscribe'))}
      </Link>
    )
  }
  return (
    <button
      type="button"
      onClick={() => setIsInterestedDialogOpen(true)}
      className={`btn ${showAsInterested ? '' : 'btn--red mt-4'} ${
        showAsPrimaryBtn ? 'btn--primary' : ''
      }`}
    >
      {striptags(t('trainingpage_interested'))}
    </button>
  )
}

export default SubscribeInterestedLink
