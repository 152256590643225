import striptags from 'striptags'
import {useTranslation} from 'next-i18next'

type PriceProps = {
  memberPrice?: string
  nonMemberPrice?: string
  isOnSubscriptionPage?: boolean
}

const Price = ({
  nonMemberPrice,
  memberPrice,
  isOnSubscriptionPage = false,
}: PriceProps) => {
  const {t} = useTranslation()

  return (
    <div className="c-training__price mt-4 d-flex flex-column flex-xl-row flex-wrap">
      <h6 className="my-0 whitespace-nowrap">
        {striptags(t('currency_format'))} {Math.round(Number(nonMemberPrice))}
        {isOnSubscriptionPage
          ? ` / ${t('booking_priceoverview_yearlylabel')}`
          : ''}
      </h6>
      {memberPrice !== nonMemberPrice ? (
        <span className="ml-2 text-sm">
          {striptags(t('trainingpage_disclaimer'))}
        </span>
      ) : null}
    </div>
  )
}

export default Price
