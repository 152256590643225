import {LevelLabel} from '.'

type DifficultyProps = {
  level?: string
  difficultyLevelToLabel: LevelLabel
  isOnSubscriptionPage?: boolean
}

const DifficultyLevel = ({
  level,
  difficultyLevelToLabel,
  isOnSubscriptionPage = false,
}: DifficultyProps) => {
  if (!level) {
    return null
  }
  return (
    <div
      className={`d-flex mr-2 align-items-center ${
        isOnSubscriptionPage ? '' : 'pb-2'
      }`}
    >
      <div className="d-flex levelIconWrapper">
        <span className="levelIcon levelIconFilled" />
        <span
          className={`levelIcon ${
            level === '2' || level === '3' ? 'levelIconFilled' : ''
          }`}
        />
        <span
          className={`levelIcon ${level === '3' ? 'levelIconFilled' : ''}`}
        />
      </div>
      <div className="c-training__hero-info-label">
        {difficultyLevelToLabel[level]}
      </div>
    </div>
  )
}
export default DifficultyLevel
