import {ReactNode, RefObject} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from 'next-i18next'
import RichText from '../../shared/RichText'
import {useMediaQuery} from '@mui/material'

type Section = {
  [key: string]: {
    ref?: RefObject<HTMLDivElement>
    content: string | ReactNode | undefined
  }
}

type SectionsProps = {
  sections: Section
  mainSection?: boolean
  sectionOddOrEven?: number
}

const MobileSections = ({
  sections,
  mainSection,
  sectionOddOrEven,
}: SectionsProps) => {
  const {t} = useTranslation()
  return (
    <div
      className={`${mainSection ? 'c-training__section-box-wrapper' : ''} ${
        !mainSection && sectionOddOrEven
          ? 'c-training__section-box-wrapper_odd'
          : 'c-training__section-box-wrapper'
      }`}
    >
      {Object.keys(sections)
        .filter(section => !!sections[section].content)
        .map(section => (
          <section id={section} className="section-box mb-0" key={section}>
            <Container fluid>
              <div className="d-flex flex-column c-training__description text-sm">
                <h2>{t(`trainingpage_${section}`)}</h2>
                {typeof sections[section]?.content === 'string' ? (
                  <RichText html={sections[section]?.content! as string} />
                ) : (
                  sections[section]?.content
                )}
              </div>
            </Container>
          </section>
        ))}
    </div>
  )
}

const DesktopSections = ({sections, mainSection}: SectionsProps) => {
  const {t} = useTranslation()

  const renderHorizontalSections = () => {
    const horizontalSectionsToDisplay = Object.keys(sections).filter(
      section => !!sections[section].content,
    )

    let mainSectionsLength = horizontalSectionsToDisplay.length

    while (mainSectionsLength != 3) {
      mainSectionsLength++
      horizontalSectionsToDisplay.push(`filler`)
    }

    return horizontalSectionsToDisplay?.map((section, i) => {
      const richtText = sections[section]?.content! as string
      return (
        <div
          className={`c-training__horizontal-section ${
            !richtText ? 'clear-background' : ''
          }`}
          key={`key-training-horizontal-section-${i}`}
        >
          {richtText ? (
            <section id={section}>
              <h2 className="c-training__horizontal-section_title">
                {t(`trainingpage_${section}`)}
              </h2>
              {typeof sections[section]?.content === 'string' ? (
                <RichText html={sections[section]?.content! as string} />
              ) : (
                sections[section]?.content
              )}
            </section>
          ) : null}
        </div>
      )
    })
  }

  const hasHorizontalSectionContent =
    !!sections?.goal?.content ||
    !!sections?.targetgroup?.content ||
    !!sections?.approach?.content

  const hasVerticalSectionContent =
    !!sections?.modules?.content || !!sections?.program?.content

  return (
    <div
      className={`${
        mainSection
          ? 'c-training__section-box-wrapper'
          : 'c-training__section-box-wrapper_odd'
      }`}
    >
      <div className="c-training_sections-desktop">
        {mainSection && hasHorizontalSectionContent ? (
          <div className="c-training__horizontal-section-wrapper d-flex">
            {renderHorizontalSections()}
          </div>
        ) : null}
        {!mainSection && hasVerticalSectionContent ? (
          <>
            {Object.keys(sections)
              .filter(section => !!sections[section].content)
              .map(section => (
                <section
                  id={section}
                  className="section-box mb-0"
                  key={section}
                >
                  <Container fluid>
                    <Row>
                      <Col
                        lg={{span: 8, offset: 1}}
                        className="d-flex flex-column c-training__description text-sm"
                      >
                        <h2>{t(`trainingpage_${section}`)}</h2>
                        {typeof sections[section]?.content === 'string' ? (
                          <RichText
                            html={sections[section]?.content! as string}
                          />
                        ) : (
                          sections[section]?.content
                        )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              ))}
          </>
        ) : null}
      </div>
    </div>
  )
}

const Sections = ({sections, mainSection, sectionOddOrEven}: SectionsProps) => {
  const isLGBreakpoint = useMediaQuery('(min-width: 992px)')

  return (
    <>
      {isLGBreakpoint ? (
        <DesktopSections sections={sections} mainSection={mainSection} />
      ) : (
        <MobileSections
          sections={sections}
          mainSection={mainSection}
          sectionOddOrEven={sectionOddOrEven}
        />
      )}
    </>
  )
}

export default Sections
