import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import React from 'react'
import {Container, Row, Col, Accordion, Card} from 'react-bootstrap'
import striptags from 'striptags'
import Image from 'next/image'
import type {Response} from '../../types/response'
import {MediaImage} from '../../types/paragraphs'
import {LevelLabel, LevelPercentage} from '../content-types/TrainingPage'
import {
  SubscriptionPageNode,
  TeacherNode,
  TrainingPageNode,
} from '../../types/content-types'
import GenericLink from './Link'
import MediaItem from './MediaItem'
import RichText from './RichText'
import SubscribeInterestedLink from '../content-types/TrainingPage/SubscribeInterestedLink'
import {SessionEntity} from '../../types/paragraphs'
import SubscribeLinkSubscription from '../content-types/Subscription/SubscribeInterestedLink'
import VacancyLevel from '../content-types/TrainingPage/VacancyLevel'
import Price from '../content-types/TrainingPage/Price'
import Language from '../content-types/TrainingPage/Language'
import DifficultyLevel from '../content-types/TrainingPage/DifficultyLevel'
import IconWithLabel from '../content-types/TrainingPage/IconWithLabel'
import sanitizeImageUrl from '../../utils/sanitize-image-url'
import styled from '@emotion/styled'
import Link247 from '../content-types/TrainingPage/Link247'
import TailorMadeButton from '../content-types/TrainingPage/TailorMadeButton'
import {formattedCourseType} from '../../utils/course-type-code'

type HeroProps = {
  isBlendedLearning?: boolean
  response?: Response | null
  trainingPage: TrainingPageNode | SubscriptionPageNode
  linkedTrainings?: any[]
  entitiesToShow?: SessionEntity[]
  showDatesLink?: boolean
  isTraining: boolean
  linkedSubscriptionTryoutPageId?: string | null
}

const Hero = ({
  isBlendedLearning = false,
  response,
  trainingPage,
  linkedTrainings,
  showDatesLink,
  entitiesToShow,
  isTraining = true,
  linkedSubscriptionTryoutPageId,
}: HeroProps) => {
  const [moduleSliderPage, setModuleSliderPage] = React.useState(0)
  const moduleSliderRef = React.useRef<HTMLDivElement>(null)
  const router = useRouter()
  const {t} = useTranslation()

  const fieldMedia = response?.included?.find(
    el => el.id === trainingPage?.relationships?.field_media?.data?.id,
  )
  const fieldMediaImage = response?.included?.find(
    el => el.id === fieldMedia?.relationships?.field_media_image?.data?.id,
  )

  const subscriptionHeroImage = `${process.env.NEXT_PUBLIC_API_URL}${fieldMediaImage?.attributes?.uri?.url}`

  const trainingPageImage = trainingPage.attributes.field_image_url

  const isSubscriptionImage =
    trainingPage.type === 'node--subscription'
      ? subscriptionHeroImage
      : trainingPageImage

  const categories = trainingPage.relationships.field_category_page?.data?.map(
    el => response?.included?.find(includedItem => includedItem.id === el.id),
  )
  const vacancyLevelClass: LevelLabel = {
    0: 'free',
    1: 'almostFull',
    2: 'full',
  }
  const vacancyLevelPercentage: LevelPercentage = {
    0: 50,
    1: 80,
    2: 100,
  }
  const vacancyLevelLabel: LevelLabel = {
    0: striptags(t('trainingpage_available')),
    1: striptags(t('trainingpage_almostbooked')),
    2: striptags(t('trainingpage_fullybooked')),
  }
  const difficultyLevelToLabel: LevelLabel = {
    0: striptags(t('trainingpage_levelnotspecified')),
    1: striptags(t('trainingpage_levelstarter')),
    2: striptags(t('trainingpage_levelmedium')),
    3: striptags(t('trainingpage_levelexpert')),
  }
  const fallback = sanitizeImageUrl(t('training_bgfallbackurl'))

  const backgroundUrl = isSubscriptionImage ? isSubscriptionImage : fallback

  const StyledSection = styled('section')`
    &::before {
      background-image: linear-gradient(0deg, #870b58d9, #870b58d9),
        url(${backgroundUrl});
    }
  `
  const isInCompany = (trainingPage as TrainingPageNode).attributes
    .field_is_in_company
  const courseTypeCode = trainingPage.attributes.field_course_type_code

  return (
    <StyledSection className="section-box c-training__hero mb-0">
      <Container fluid>
        <Row>
          <Col
            lg={{span: isBlendedLearning ? 7 : 6}}
            className="c-training__hero-intro text-white"
          >
            <div className="d-flex align-items-center mb-3">
              {categories
                ?.filter(category => !!category)
                .map(category => {
                  const categoryIcon = response?.included?.find(
                    el => el.id === category.relationships.field_icon?.data?.id,
                  ) as MediaImage
                  return (
                    <React.Fragment key={category.id}>
                      <MediaItem
                        layout="fixed"
                        wrapperClass="d-flex mr-2"
                        item={categoryIcon}
                        height={24}
                        width={24}
                        response={response}
                      />
                      <GenericLink
                        key={category.id}
                        link={category.attributes.path}
                        extraClass={`c-training__hero-intro-category text-white mr-2`}
                      >
                        {category.attributes.title}
                      </GenericLink>
                    </React.Fragment>
                  )
                })}
            </div>

            <h1 className="c-training__hero-intro-title">
              {trainingPage.attributes.title}
            </h1>
            <RichText
              extraClass="c-training__hero-intro-text"
              html={
                isTraining
                  ? (trainingPage as TrainingPageNode).attributes
                      .field_goal_description?.processed
                  : trainingPage.attributes.field_content_description?.processed
              }
            />
            {isTraining &&
            (trainingPage as TrainingPageNode).relationships.field_teacher
              ?.data &&
            (trainingPage as TrainingPageNode).relationships.field_teacher!.data
              .length > 0 ? (
              <>
                <span className="d-none d-lg-block c-training__hero-trainersTitle mb-3 mt-4">
                  {striptags(t('trainingpage_trainers'))}
                </span>
                <div className="d-none d-lg-flex align-items-center">
                  <div className="d-flex c-training__hero-teacherWrapper">
                    {(
                      trainingPage as TrainingPageNode
                    ).relationships.field_teacher!.data.map(includedTeacher => {
                      const teacher = response?.included?.find(
                        el => el.id === includedTeacher.id,
                      ) as TeacherNode
                      if (!teacher || !teacher.attributes.field_image_url) {
                        return null
                      }
                      return (
                        <div key={teacher.id}>
                          <Image
                            className="c-training__hero-teacherImg"
                            width={50}
                            height={50}
                            src={teacher.attributes.field_image_url}
                            alt={teacher.attributes.field_last_name}
                            sizes="50px"
                          />
                        </div>
                      )
                    })}
                  </div>
                  <div className="d-flex flex-wrap">
                    {(
                      trainingPage as TrainingPageNode
                    ).relationships.field_teacher?.data.map(
                      (includedTeacher, i) => {
                        const teacher = response?.included?.find(
                          el => el.id === includedTeacher.id,
                        ) as TeacherNode
                        if (!teacher) {
                          return null
                        }
                        return (
                          <React.Fragment key={teacher.id}>
                            <span>
                              {teacher.attributes.field_first_name}{' '}
                              {teacher.attributes.field_last_name}
                            </span>
                            {i !==
                            (trainingPage as TrainingPageNode).relationships
                              .field_teacher!.data.length -
                              1 ? (
                              <span className="mr-1">,</span>
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        )
                      },
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </Col>
          <Col
            lg={{
              span: isBlendedLearning ? 5 : 4,
              offset: isBlendedLearning ? 0 : 1,
            }}
          >
            <div
              className={`c-training__hero-info bg-white text-sm d-flex flex-column`}
            >
              <h2 className={`mb-3`}>
                {striptags(t('trainingpage_traininginformation'))}
              </h2>
              <Language
                locale={router.locale}
                t={t}
                linkedTrainings={linkedTrainings}
              />
              <IconWithLabel
                isCourseType
                label={
                  isTraining
                    ? trainingPage?.attributes?.field_course_type_code
                    : 'subscription'
                }
              />
              <IconWithLabel
                icon="time"
                label={trainingPage.attributes.field_learning_pace}
              />
              <DifficultyLevel
                level={trainingPage.attributes.field_difficulty_level}
                difficultyLevelToLabel={difficultyLevelToLabel}
              />
              {isTraining ? (
                <>
                  <IconWithLabel
                    icon="kmo"
                    label={t('trainingpage_kmowallet')}
                    show={
                      (trainingPage as TrainingPageNode).attributes
                        .field_kmo_portefeuille_payment
                    }
                  />
                  <IconWithLabel
                    icon="kmo"
                    label={t('trainingpage_chequesformation')}
                    show={
                      (trainingPage as TrainingPageNode).attributes
                        .field_cheques_formation_payment
                    }
                  />
                  <IconWithLabel
                    icon="certificate"
                    label={striptags(t('trainingpage_certificate'))}
                    show={
                      (trainingPage as TrainingPageNode).attributes
                        .field_contain_exam
                    }
                  />
                </>
              ) : null}
              {showDatesLink ? (
                <a
                  href="#dates"
                  aria-label="dates and locations link"
                  className="text-underline c-training__hero-info-label"
                  dangerouslySetInnerHTML={{
                    __html: t('trainingpage_dateslocations'),
                  }}
                />
              ) : null}
              {isTraining ? (
                <>
                  <VacancyLevel
                    level={
                      (trainingPage as TrainingPageNode).attributes
                        .field_vacancy_level
                    }
                    vacancyLevelClass={vacancyLevelClass}
                    vacancyLevelPercentage={vacancyLevelPercentage}
                    vacancyLevelLabel={vacancyLevelLabel}
                  />

                  <div className="d-flex flex-column d-lg-none">
                    <Accordion>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            className="c-training__hero-modules__toggle p-0 mt-3"
                            eventKey="0"
                            data-open={false}
                            onClick={e => {
                              if (
                                (e.currentTarget as HTMLButtonElement).dataset
                                  .open === 'true'
                              ) {
                                ;(
                                  e.currentTarget as HTMLButtonElement
                                ).dataset.open = 'false'
                              } else {
                                ;(
                                  e.currentTarget as HTMLButtonElement
                                ).dataset.open = 'true'
                              }
                            }}
                          >
                            {striptags(t('trainingpage_modulestoggle'))}
                            <svg
                              width="14"
                              height="9"
                              fill="none"
                              className="ml-1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m13.781 1.25-.594-.625c-.156-.156-.406-.156-.53 0L7 6.281 1.312.625C1.188.469.938.469.782.625l-.595.625c-.156.125-.156.375 0 .531L6.72 8.313a.36.36 0 0 0 .531 0l6.531-6.532c.156-.156.156-.406 0-.531Z"
                                fill="#E4003A"
                              />
                            </svg>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="mt-3">
                            {entitiesToShow?.map(entity => (
                              <div
                                key={entity.id}
                                className="d-flex flex-column bg-white mb-3"
                              >
                                <div className="d-flex flex-column px-2 py-2">
                                  <span>
                                    {entity.attributes.field_commercial_name}
                                  </span>
                                </div>
                                <div className="c-training__hero-modules__slide-tags d-flex px-2 py-2 align-items-center">
                                  <span className="mt-2" key={entity.id}>
                                    {striptags(
                                      t(
                                        `course_type_${formattedCourseType(
                                          entity.attributes
                                            .field_course_type_code,
                                        )}`,
                                      ),
                                    )}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </>
              ) : null}
              <Price
                memberPrice={trainingPage.attributes.field_price_member}
                nonMemberPrice={trainingPage.attributes.field_price_non_member}
                isOnSubscriptionPage={!isTraining}
              />
              {isTraining ? (
                <>
                  <SubscribeInterestedLink
                    trainingPage={trainingPage as TrainingPageNode}
                    courseTypeCode={courseTypeCode}
                  />
                  {isInCompany && striptags(t('trainingpage_askoffer')) ? (
                    <TailorMadeButton
                      trainingId={
                        +(trainingPage as TrainingPageNode).attributes
                          .field_training_id
                      }
                    />
                  ) : null}
                  <Link247 courseTypeCode={courseTypeCode} />
                </>
              ) : (
                <SubscribeLinkSubscription
                  subscriptionPage={trainingPage as SubscriptionPageNode}
                  courseTypeCode={courseTypeCode}
                  linkedSubscriptionTryoutPageId={
                    linkedSubscriptionTryoutPageId
                  }
                />
              )}
            </div>
          </Col>
          {isBlendedLearning ? (
            <Col
              lg={{span: 12}}
              className="d-none d-lg-flex c-training__hero-modules flex-column"
            >
              <h3 className="text-white">
                {striptags(t('trainingpage_modulestitle'))}
              </h3>
              <div className="d-flex align-items-center">
                {entitiesToShow && entitiesToShow.length > 4 ? (
                  <button
                    type="button"
                    onClick={() => {
                      if ((moduleSliderPage + 1) * 4 - 4 > 0) {
                        setModuleSliderPage(moduleSliderPage - 1)
                      }
                    }}
                    disabled={moduleSliderPage === 0}
                    className="mr-4 c-training__hero-modules__slide-btn rotated"
                    aria-label="Previous slide"
                  >
                    <svg
                      width="27"
                      height="28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 27c7.18 0 13-5.82 13-13s-5.82-13-13-13S.5 6.82.5 14s5.82 13 13 13Z"
                        stroke="#303642"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="m13.5 19.2 5.2-5.2-5.2-5.2M8.3 14h10.4"
                        stroke="#303642"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                ) : null}
                <div
                  ref={moduleSliderRef}
                  className="c-training__hero-modules__slider d-flex"
                >
                  <div
                    className="c-training__hero-modules__sliderInnerWrapper d-flex"
                    style={{
                      transform: `translateX(calc(${moduleSliderPage}*(-${
                        moduleSliderRef.current?.clientWidth ?? 0
                      }px - 0.5%)))`,
                    }}
                  >
                    {entitiesToShow?.map(entity => (
                      <div
                        key={entity.id}
                        className="d-flex flex-column c-training__hero-modules__slide"
                      >
                        <div className="d-flex flex-column px-4 py-3">
                          <span>{entity.attributes.field_commercial_name}</span>
                        </div>
                        <div className="c-training__hero-modules__slide-tags d-flex px-4 py-3 align-items-center">
                          {entity.attributes.field_course_type_code ? (
                            <span className="mt-2">
                              {striptags(
                                t(
                                  `course_type_${formattedCourseType(
                                    entity.attributes.field_course_type_code,
                                  )}`,
                                ),
                              )}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {entitiesToShow && entitiesToShow.length > 4 ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (moduleSliderPage * 4 + 4 < entitiesToShow.length) {
                        setModuleSliderPage(moduleSliderPage + 1)
                      }
                    }}
                    disabled={moduleSliderPage === 1}
                    className="ml-4 c-training__hero-modules__slide-btn"
                    aria-label="Next slide"
                  >
                    <svg
                      width="27"
                      height="28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 27c7.18 0 13-5.82 13-13s-5.82-13-13-13S.5 6.82.5 14s5.82 13 13 13Z"
                        stroke="#303642"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="m13.5 19.2 5.2-5.2-5.2-5.2M8.3 14h10.4"
                        stroke="#303642"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                ) : null}
              </div>
            </Col>
          ) : null}
        </Row>
      </Container>
    </StyledSection>
  )
}

export default Hero
